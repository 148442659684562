<template>
  <div>
    <v-toolbar flat>
      <v-toolbar-title>Products</v-toolbar-title>
      <v-divider class="mx-2" inset vertical></v-divider>
      <v-btn color="primary" dark to="/products/detail" class="mb-2 ma-2"
        >Insert</v-btn
      >
    </v-toolbar>
    <v-data-table
      :headers="headers"
      :items="result.data"
      :options.sync="options"
      :items-per-page="1000"
      class="elevation-1"
    >
      <template v-slot:item.action="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">edit</v-icon>
        <v-icon small @click="deletePopup(item)">delete</v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="currentPage"
        :total-visible="20"
        @input="initialize"
        :length="result.totalPage"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title class="text-h5 red lighten-2">
          Warning
        </v-card-title>
        <v-card-text class="text-h5">
          Are you sure you want to delete?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItem()">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import router from "@/router";

export default {
  data() {
    return {
      dialog: false,
      currentItem: {},
      all: [],
      headers: [
        { text: "Id", value: "id" },
        { text: "State", value: "state" },
        { text: "Currency", value: "currency" },
        { text: "Price", value: "price" },
        { text: "Type", value: "type" },
        { text: "Priority", value: "priority" },
        { text: "Value", value: "value" },
        { text: "FreeSpin", value: "freeSpin" },
        { text: "Gems", value: "gems" },
        { text: "OldValue", value: "oldValue" },
        { text: "Image", value: "image" },
        { text: "DiscountRate", value: "discountRate" },
        { text: "IsConsume", value: "isConsume" },
        { text: "Order", value: "order" },
        { text: "Actions", value: "action" },
      ],
      page: 1,
      total: 0,
      options: {},
      pageCount: 0,
      currentPage: 1,
      itemsPerPage: 500,
      singleSelect: false,
      selected: [],
      result: [],
      pagination: {
        sortBy: "id",
      },
    };
  },
  methods: {
    async deletePopup(item) {
      this.dialog = true;
      this.currentItem = item;
    },
    async deleteItem() {
      await this.$store.dispatch("products/delete", this.currentItem.id);
      await this.initialize();
      this.dialog = false;
    },
    editItem(item) {
      router.push("/products/detail/" + item.id);
    },
    async initialize() {
      // eslint-disable-next-line no-console
      console.log("test");
      this.result = await this.$store.dispatch("products/getAll", {
        page: this.currentPage - 1,
      });
      this.all = this.$store.state.products.all;
    },
  },

  async mounted() {
    await this.initialize();
  },
};
</script>
